<template>
    <div  v-if="hasShow">
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 footer-links">
                            <ul>
                                <li><span  class="bx-color-1 bx-color-2"><router-link   class="bx-color-1 bx-color-2" style="border: none;" to="/about">{{ $t('footer.AboutUs') }}</router-link></span></li>
                                <li><span  class="bx-color-1 bx-color-2"><router-link   class="bx-color-1 bx-color-2" style="border: none;" to="/consult">{{ $t('footer.CollaborativeConsulting') }}</router-link></span></li>
                            </ul>
                        </div>

                        <div class="col-lg-4 col-md-6 footer-links">
                            <ul>
                                <li><span class="bx-color-1">{{ $t('footer.Businesscooperation') }}</span></li>
                                <li>
									<div style="display: flex;flex-direction: row;margin-top: 4px;">
										<div>
											<span class="bx-color-1">{{ $t('footer.phone') }}</span>
										</div>
										<div style="display: flex;flex-direction: column;">
											<span class="bx-color-1">{{ $t('footer.phone2') }}</span>
											<span class="bx-color-1" style="margin-top: 8px;">{{ $t('footer.phone3') }}</span>
										</div>
									</div>
								</li>
                            </ul>
                        </div>
						
						<div class="col-lg-4 col-md-6 footer-links">
						    <ul>
						        <li>
									<img src="../assets/img/header/phone.png"  class="bx-img"/>
									<span class="bx-color-1">{{ $t('footer.tel') }}</span>
								</li>
						        <li>
									<img src="../assets/img/header/address.png"  class="bx-img"/>
									<span class="bx-color-1">{{ $t('footer.address') }}</span>
								</li>
						    </ul>
						</div>
                    </div>
					<div class="row">
						<hr class="container"/>
					</div>
					<div class="row">
						<div class="copyright bx-color-1" style="margin-top: 25px;">
						    {{ $t('footer.copyright') }}<a href="https://beian.miit.gov.cn/" target="_blank" style="cursor: pointer;color: #E0E0E0;padding-left: 10px;">{{ $t('footer.icp') }}</a>
						</div>
					</div>
                </div>
            </div>
        </footer>
		<Floating :isActive="hasActive" v-if="hasActive"></Floating>
    </div>
</template>

<script>
import Floating from '@/components/Floating.vue';
export default {
    //import 引入的组件需要注入到对象中才能使用
    components: {
		Floating
	},
    props: {
		hasShow:{
			default: false, // 设置两个值，goodsDetail和cart，样式不同
			type: Boolean
		},
		isActive:{
			default: false, // 设置两个值，goodsDetail和cart，样式不同
			type: Boolean
		}
	},
    data() {
        //这里存放数据
        return {
			hasActive:false
        };
    },
    //计算属性 类似于 data 概念
    computed: {},
    watch: {},
    methods: {
		
    },
    created() {

    },
    mounted() {
		this.hasActive=this.isActive
    },
}
</script>
<style scoped>
	.footer-top{
		/* height: 244px; */
		background: #1C1D1E !important;
		border-radius: 0px 0px 0px 0px;
	}
	
	.bx-color-1{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #E0E0E0;
		line-height: 16px;
		text-align: left;
		font-style: normal;
		text-transform: none;
	}
	.bx-color-2{
		color: #E0E0E0 !important;
		/* text-decoration: underline; */
		border-bottom: 1px solid #E0E0E0;
		padding-bottom: 2px;
	}
	.bx-color-2:hover{
		color: #10B1A7 !important;
		border-bottom: 1px solid #10B1A7;
	}
	.bx-img{
		width: 14px;
		height: 14px;
		margin-right: 3px;
	}
</style>