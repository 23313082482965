import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AiMode from '../views/AiMode.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    //redirect: '/:lang?/home',
	component: () => import( '../views/Home.vue')
  },
  {
    path: '/:lang?/home',
    name: 'Home',
    component: () => import( '../views/Home.vue')
  },
  {
    path: '/:lang?/largeModel',
    name: 'LargeModel',
    component: () => import( '../views/LargeModel.vue')
  },
  {
    path: '/:lang?/aiCapacity',
    name: 'AiCapacity',
    component: () => import( '../views/AiCapacity.vue')
  },
  {
    path: '/:lang?/aiMode',
    name: 'AiMode',
    component: () => import( '../views/AiMode.vue')
  },
  {
    path: '/:lang?/programme',
    name: 'Programme',
    component: () => import( '../views/Programme.vue')
  },
  {
    path: '/:lang?/programmeDetails',
    name: 'ProgrammeDetails',
    component: () => import( '../views/ProgrammeDetails.vue')
  },
  {
    path: '/:lang?/product',
    name: 'Product',
    component: () => import( '../views/Product.vue')
  },
  {
    path: '/:lang?/medium',
    name: 'Medium',
    component: () => import( '../views/Medium.vue')
  },
  {
    path: '/:lang?/mediumDetails',
    name: 'MediumDetails',
    component: () => import( '../views/MediumDetails.vue')
  },
  {
    path: '/:lang?/about',
    name: 'About',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/:lang?/consult',
    name: 'Consult',
    component: () => import( '../views/consult.vue')
  },
  {
    path: '/:lang?/register',
    name: 'Register',
    component: () => import( '../views/register.vue')
  },
  {
    path: '/:lang?/recorderCloud',
    name: 'RecorderCloud',
    component: () => import( '../views/recorderCloud.vue')
  },
  {
    path: '/:lang?/recorderDetails',
    name: 'RecorderDetails',
    component: () => import( '../views/recorderDetails.vue')
  },
  {
    path: '/:lang?/writing',
    name: 'Writing',
    component: () => import( '../views/writing.vue')
  },
  {
    path: '/:lang?/writingDetails',
    name: 'WritingDetails',
    component: () => import( '../views/writingDetails.vue')
  },
  {
    path: '/:lang?/dialog',
    name: 'Dialog',
    component: () => import( '../views/dialog.vue')
  },
  {
    path: '/:lang?/personalCenter',
    name: 'PersonalCenter',
    component: () => import( '../views/personalCenter.vue')
  },
  {
    path: '/:lang?/accountSecurity',
    name: 'AccountSecurity',
    component: () => import( '../views/accountSecurity.vue')
  },
  {
    path: '/:lang?/apply',
    name: 'apply',
    component: () => import( '../views/apply.vue')
  },
  {
    path: '/:lang?/download/:name?',
    name: 'download',
    component: () => import( '../views/download.vue')
  },
  {
    path: '/:lang?/download/:name?/phone',
    name: 'download',
    component: () => import( '../views/phone.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;
  if (lang) {
	  // console.log(router.app.$options.i18n.locale)
    // 如果路径中包含语言参数，切换语言并刷新页面
	let langlocale=(lang=== 'en'?'en':'zh');
	router.app.$options.i18n.locale = langlocale;
	localStorage.setItem('lang', langlocale);
    //router.app.$i18n.locale = lang;
    next();
  } else {
    // 如果没有语言参数，保持当前语言
    next();
  }
});

//解决点击重复的path报错问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  console.log("路由信息",to);
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
